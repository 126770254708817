// @flow
import { gigyaEnvironments } from './config';

const GIGYA_API_KEYS = {
  default:
    process.env.NODE_ENV === 'development'
      ? '3_1Slde_K8CjHFuRHcLx575WCvK3TI6ZFOZsbkzjfk8B5PcA_C9YX6uoMX1bWCfW1y'
      : // this placeholder will get replaced by a build script to inject the right API key
        // for the corresponding environment where the app is being deployed
        '3_CezoQ3fTB5Yqt6BJj5nzZOkyyRpsH9dTeNZ-Wm_P14KOM17jnnfSo-v5UdtzVYYt',
  ticketing:
    process.env.NODE_ENV === 'development'
      ? '3_bg_uicWzxuVoxiZI1t0OtwDYD91sdhf2_XTGgkXUDocspz6jacw7T6jwynUoAbxi'
      : // this placeholder will get replaced by a build script to inject the right ticketing API key
        // for the corresponding environment where the app is being deployed
        '3_SdL4E6-ePO8KQuY7RDO6Zyw3NhjZ91-aJLtQeb22OlLUAfeAKJRe7ze835pgksW7',
};

export const getGigyaApiKey = (env?: string) => {
  switch (env) {
    case gigyaEnvironments.DEFAULT:
      return GIGYA_API_KEYS.default;
    case gigyaEnvironments.TICKETING:
      return GIGYA_API_KEYS.ticketing;
    default:
      return GIGYA_API_KEYS.default;
  }
};
